export function isotope(value) {


    // init Isotope
    let $grid = value;

// store filter for each group

    $('.filter-container').on('click', '.button', function (event) {
        filter_products(event.currentTarget)
    });

    function filter_products(e) {
        var button = $(e);
        if (button.hasClass('is-checked')) {
            $(button.attr('data-filter')).each(function (index, value) {
                $(value).removeClass('is-checked');
            });
            $grid.isotope({filter: "*"});
        } else {
            $('.filter-button').removeClass('is-checked');
            $(button.attr('data-filter')).each(function (index, value) {
                $(value).addClass('is-checked');
            });

            $grid.isotope({filter: button.attr('data-filter')});
        }
    }

    function getHashFilter() {
        var matches = location.hash.match(/filter=([^&]+)/i);
        var hashFilter = matches && matches[1];
        return hashFilter && decodeURIComponent(hashFilter);
    }
    var isIsotopeInit = false;

    function onHashchange() {
        var hashFilter = getHashFilter();
        if (!hashFilter && isIsotopeInit) {
            return;
        }
        isIsotopeInit = true;
        // filter isotope
        // set selected class on button
        if (hashFilter) {
            filter_products($('.filter-button-group').find('[data-filter="' + hashFilter + '"]'));
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".filter-title").offset().top/* - 75*/
            }, 500);
        }
    }

    $(window).on('hashchange', onHashchange);
    // trigger event handler to init Isotope
    onHashchange();
}
