"use strict";

/*global
 window, document, console, require, Foundation
 */
let $grid = $('#product-grid').isotope({
    itemSelector: '.filter-product'
});
let mobile_navigation_open = false;
import 'lib/foundation-modules';
import {isotope} from "./isotope_conf";
import {ajax_calls} from "./ajax-calls";

document.addEventListener('DOMContentLoaded', () => {
    $(document).foundation();
    $('.toggle-mobile-nav').click(function () {
        $('.site-header').toggleClass('mobile-nav-open');
        mobile_navigation_open = !mobile_navigation_open;
    });
    $('#nav-menu .submenu').click(function () {
        if(!Foundation.MediaQuery.atLeast('medium')){
            $('#example-menu').css('display','none');
        }

    });
    $(window).on('toggled.zf.responsiveToggle', function () {
    });
    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {

        if (Foundation.MediaQuery.atLeast('medium') && oldSize === 'small') {
            $('.site-header').removeClass('mobile-nav-open');
        }
    });

    $('article').find('img.alignleft').each(function (index, value) {
        //console.log("",$(value));
        $(value).parent().addClass('align-left');
    });

    //test for touch events support and if not supported, attach .no-touch class to the HTML tag.
    if (!("ontouchstart" in document.documentElement)) {

        document.documentElement.className += " no-touch";
    }

    isotope($grid);
    ajax_calls();
    $('.is-accordion-submenu-item').click(function (e) {
        $('.site-header').toggleClass('mobile-nav-open');
        mobile_navigation_open = !mobile_navigation_open;
    });
    $('.filter-button .info-container').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        let reveal_element = $(e.currentTarget).data('open');
        $('#'+reveal_element).foundation('toggle');
    });

    let lastScrollTop = 100;
    const navigationbar = $('.navigation-bar');
    $(window).scroll(function(event){

        if(!mobile_navigation_open){
            var st = $(this).scrollTop();
            if (st > lastScrollTop && st > 75){
                navigationbar.addClass('hidden');
                $('.is-dropdown-submenu').addClass('hidden');
            } else {
                navigationbar.removeClass('hidden');
                $('.is-dropdown-submenu').removeClass('hidden');
            }
        }
        lastScrollTop = st;
    });



});






