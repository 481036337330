export function ajax_calls() {


    let articleGrid = $('.article-grid');
    let post_cat = '';
    if (articleGrid.length) {

        articleGrid.isotope({
            itemSelector: '.article-preview-container',
            layoutMode: 'fitRows'
        });

        let pageNum = 1;


        $('.article-filter-button-group button').on('click', function () {

            $('.article-filter-button-group button.is-checked').removeClass('is-checked');
            post_cat = ($(this).data('filter') === '*') ? '' : $(this).data('filter');

            $(this).addClass('is-checked');
            let data = {
                'action': 'get_filtered_posts',
                'post_cat': post_cat
            };
            $('.article-preview-container').addClass('scale-out-center');

            $.ajax({

                url: brbrApp.ajaxUrl, // AJAX handler
                data: data,
                type: 'POST',

                success: function (data) {
                    // console.log("",data);
                    if (data) {
                        $('button.loadmore').show().removeClass('disabled');

                        pageNum = 1;

                        articleGrid.empty();

                        var items = $.parseJSON(data);
                        $.each(items['article'], function (index, value) {
                            articleGrid.append($(value)).isotope('appended', $(value));
                        });

                        if ($('.article-preview-container').length === parseInt(items['max'])) {
                            $('button.loadmore').hide(); // if no data, remove the button as well
                        }
                        articleGrid.isotope('reloadItems');
                        articleGrid.isotope();

                        if (items.length) {

                            //button.removeClass('disabled');
                        } else {
                            //button.remove();
                        }

                    } else {
                        //button.remove(); // if no data, remove the button as well
                    }
                },
                error: function () {
                    //console.log(data, arguments)
                }
            });

        });


///===========LATAA LISÄÄ PAINIKE=============///

        $('button.loadmore').on('click', function (e) {

            pageNum++;
            let articleFilter = $('.article-filter-button-group button.is-checked').data('filter');

            post_cat = (articleFilter === '*') ? '' : articleFilter;


            e.preventDefault();

            let button = $(this),
                data = {
                    'action': 'loadmore',
                    'pageNumber': pageNum,
                    'post_cat': post_cat,
                    'post_per_page': 6
                };


            $.ajax({
                url: brbrApp.ajaxUrl, // AJAX handler
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.addClass('disabled');
                },
                success: function (data) {
                    if (data) {

                        var items = $.parseJSON(data);
                        $.each(items['article'], function (index, value) {

                            articleGrid.append($(value)).isotope('appended', $(value));
                        });
                        if ($('.article-preview-container').length === parseInt(items['max'])) {
                            button.hide(); // if no data, remove the button as well
                        }
                        articleGrid.isotope('reloadItems');
                        articleGrid.isotope();

                        if (items['article'].length) {
                            button.removeClass('disabled');
                        } else {
                            button.hide(); // if no data, remove the button as well
                        }

                    } else {
                        button.hide(); // if no data, remove the button as well
                    }
                }, error: function () {
                }
            });

        });

    }


    ///===========ARTIKKELI KARUSELLI=============///
/*    let articlePagesNum = 2,
        articleCarusel = $('.article-slider-wrapper');

    if (articleCarusel.length) {

        articleCarusel.isotope({
            itemSelector: '.article-preview-container',
            layoutMode: 'fitRows'
        });

        $('#more_articles').on('click', function (e) {


            e.preventDefault();

            let button = $(this),
                post_per_page = 3,
                data = {
                    'action': 'loadmore',
                    'pageNumber': articlePagesNum,
                    'post_per_page': post_per_page,
                    'post_cat': ''
                };
            $.ajax({
                url: brbrApp.ajaxUrl, // AJAX handler
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.addClass('disabled');
                },
                success: function (data) {
                    if (data) {

                        $('.article-preview-container').addClass('scale-out-center');
                        articlePagesNum++;
                        articleCarusel.empty();
                        var items = $.parseJSON(data);
                        $.each(items['article'], function (index, value) {

                            articleCarusel.append($(value)).isotope('appended', $(value));
                        });
                        if (post_per_page * articlePagesNum >= parseInt(items['max'])) {
                            //button.hide(); // if no data, remove the button as well
                            articlePagesNum = 1;
                        }
                        articleCarusel.isotope('reloadItems');
                        articleCarusel.isotope();

                        if (items['article'].length) {
                            button.removeClass('disabled');
                        } else {
                            button.hide(); // if no data, remove the button as well
                        }

                    } else {
                        button.hide(); // if no data, remove the button as well
                    }
                }, error: function () {
                }
            });

        });


    }*/
}